import { z } from 'zod';
import { validatePhoneNumber } from './phone-number-validate-utils';

export const generateRequiredSchema = (label: string) => {
  return z.string().min(1, `${label}Required`);
};

export const generateNameSchemaMigrated = (label = 'firstName') => {
  return generateRequiredSchema(label)
    .refine((value) => value.trim() !== '', {
      message: `${label}Required`,
    })
    .refine((value) => validateName(value), {
      message: 'nameInvalidError',
    });
};

export const generateEmailSchema = () => {
  return generateRequiredSchema('email')
    .email('emailInvalidError')
    .max(255, 'emailTooLongError')
    .refine((value) => value.trim() !== '', {
      message: 'emailRequired',
    })
    .refine((value) => !/\s/.test(value), {
      message: 'emailWhitespaceError',
    });
};

export const generatePhoneNumberSchema = () => {
  return generateRequiredSchema('phoneNumber')
    .refine((value) => value.trim() !== '', {
      message: 'phoneNumberRequired',
    })
    .refine((value) => /^\(\+\d+\)/.test(value), {
      message: 'countryCodeRequired',
    })
    .refine(
      (value) => {
        return validatePhoneNumber(value);
      },
      {
        message: 'phoneNumberInvalidError',
      },
    );
};

export const generateNameSchema = (label = 'First name') => {
  const requiredMessage = `${label} is required`;
  return z
    .string()
    .min(1, requiredMessage)
    .refine((value) => value.trim() !== '', {
      message: requiredMessage,
    })
    .refine((value) => validateName(value), {
      message:
        'Special characters are not allowed in this field. Please remove any special characters and enter your name using standard English letters only.',
    });
};

// only accepts alphanumeric characters
// does not accept special characters
export const validateName = (value: string) => {
  return /^\s*[\dA-Za-z]+(?:[ -]*[\dA-Za-z]+)*\s*$/.test(value);
};
